import * as THREE from 'three';
import { ColorManagement, Euler, Scene } from 'three';
import { onResizeFunctions } from '../../App';
import { AppDispatch, RootState } from '../../redux/Store';
import { BaseCanvas } from '../canvasComponents/base/BaseCanvas';

export abstract class BaseThreeJs extends BaseCanvas {
    protected renderer?: THREE.WebGLRenderer;
    protected scene?: THREE.Scene;
    protected camera?: THREE.PerspectiveCamera;
    protected directionalLight?: THREE.DirectionalLight;
    // protected initialState: RootState;

    private prevTime = 0;
    private lastDelta = 0;
    private aspectRatio = 1;

    constructor(div: HTMLDivElement | HTMLCanvasElement, appDispatch: AppDispatch) {
        super(div, appDispatch);
        this.init();
    }

    public Resize(): void {
        if (this.camera) {
            // this.camera.aspect = window.innerWidth / window.innerHeight;
            // this.camera?.updateProjectionMatrix();
        }

        this.renderer?.setSize(this.parentDiv.clientWidth, this.parentDiv.clientWidth / this.aspectRatio);
    }

    public Stop(): void {
        this.clean();
    }

    private init(): void {
        this.camera = new THREE.PerspectiveCamera(70, this.parentDiv.clientWidth / this.parentDiv.clientHeight, 0.01, 100);
        this.camera.position.x = 0;
        this.camera.position.y = 0;
        this.camera.position.z = 1.5;

        this.scene = new THREE.Scene();

        this.lightSetup(this.scene);

        const light = new THREE.AmbientLight(0xffffff);
        light.intensity = .5;
        this.scene.add(light);

        ColorManagement.enabled = true;


        this.renderer = new THREE.WebGLRenderer({ antialias: true, precision:'highp', alpha: true,  });
        this.renderer.outputColorSpace = THREE.SRGBColorSpace;

        this.renderer.setSize(this.parentDiv.clientWidth, this.parentDiv.clientHeight);
        this.renderer.setAnimationLoop((time) => this.animation(time));

        this.aspectRatio = this.parentDiv.clientWidth / this.parentDiv.clientHeight;

        while (this.parentDiv.firstChild) {
            this.parentDiv.removeChild(this.parentDiv.firstChild);
        }

        this.parentDiv.appendChild(this.renderer.domElement);

        onResizeFunctions.push({ ref: this, function: () => this.Resize() })

    }

    private lightSetup(scene: Scene) {
        this.directionalLight = new THREE.DirectionalLight(0xffffff);
        this.directionalLight!.intensity = 3;
        // this.directionalLight!.setRotationFromEuler(new Euler(Math.PI / 2, Math.PI / 2, Math.PI / 2));
        // this.directionalLight!.rotation.x = Math.PI / 180 * 40;
        this.directionalLight!.position.set(10,5,2);
        this.directionalLight!.castShadow = true;


        const light = this.directionalLight!;
        light.shadow.mapSize.width = 1024
        light.shadow.mapSize.height = 1024
        light.shadow.camera.near = 0.5
        light.shadow.camera.far = 25
        // light.shadow.camera.left = -10
        // light.shadow.camera.right = 10
        // light.shadow.camera.top = 10
        // light.shadow.camera.bottom = -10
        light.shadow.radius = 5
        light.shadow.blurSamples = 25
        // this.directionalLight!.shadow.camera.top = 180;
        // this.directionalLight!.shadow.camera.bottom = - 100;
        // this.directionalLight!.shadow.camera.left = - 120;
        // this.directionalLight!.shadow.camera.right = 120;
        scene.add(this.directionalLight!);
    }

    protected animation(time: number) {
        this.renderer?.render(this.scene!, this.camera!);

        const delta = time - this.prevTime;
        if (delta < this.lastDelta)
            this.lastDelta = delta;

        this.prevTime = time;

        this.runAnimations(delta);
    }

    protected runAnimations(delta: number): void { }

    public clean(): void {
        onResizeFunctions.splice(onResizeFunctions.indexOf(onResizeFunctions.filter(obj => obj.ref === this)[0]), 1);
        this.renderer?.dispose();
        this.parentDiv.removeChild(this.renderer!.domElement);
    }
}