
// import { DRESS_SIDES } from "redux/slices/DressSidesSlice";
// import { AppDispatch } from "redux/Store";
import { Color, Mesh, MeshBasicMaterial, MeshStandardMaterial, Object3DEventMap, PCFSoftShadowMap, PlaneGeometry, SpotLight, TextureLoader, VSMShadowMap } from "three";
import { lerp } from "three/src/math/MathUtils";
import { AppDispatch } from "../../../redux/Store";
import { BaseThreeJs } from "../BaseThreeJs";
// import { setDressMaterial } from "../Utils";
import { assetsList, loadAssetsFromList } from "../utils/ThreeUtils";

export class CheckoutThree extends BaseThreeJs {
    private flag?: any; //Mesh<PlaneGeometry, MeshBasicMaterial, Object3DEventMap>;
    constructor(div: HTMLDivElement | HTMLCanvasElement, appDispatch: AppDispatch) {
        super(div, appDispatch);

        // this.createMesh();
        loadAssetsFromList([
            { key: 'Shelf', url: 'scene.glb' }
        ], (percent) => {
        }).then(() => {
            this.applyPlayerSettings();
        })
    }

    private createMesh(): void{
        const loader = new TextureLoader();


        var geometry = new PlaneGeometry(5, 3, 50, 30)
        var material = new MeshBasicMaterial({
            opacity: 0,
            transparent: true,
            map: loader.load("dress_texture.jpg", () => {
                material.opacity = 1
            }),
            // wireframe: true,
            // color: 0x2727e6
        })

        this.flag = new Mesh(geometry, material)
        this.flag.rotation.set(-0.1, 0, 0)

        this.scene?.add(this.flag)


    }

    private addSpotLight(): void{
        const spotLight = new SpotLight(0xffffff);
        spotLight.position.set(2, 5, 1.5);
        spotLight.angle = Math.PI * 15 / 180;
        spotLight.penumbra = 1;
        spotLight.decay = 2;
        spotLight.distance = 0;
        spotLight.intensity = 200;

        spotLight.castShadow = true;
        // spotLight.shadow.bias = .001;

        const light = spotLight;

         light.shadow.mapSize.width = 1024
        light.shadow.mapSize.height = 1024
            light.shadow.camera.near = 0.5
            light.shadow.camera.far = 25
            // light.shadow.camera.left = -10
            // light.shadow.camera.right = 10
            // light.shadow.camera.top = 10
            // light.shadow.camera.bottom = -10
            light.shadow.radius = 50;
            light.shadow.blurSamples = 3;
        
        this.renderer!.shadowMap.enabled = true;
        this.renderer!.shadowMap.type = PCFSoftShadowMap;


        this.scene!.add(spotLight);
    }

    private cameraLerpFactor = 0;
    protected override animation(time: number) {
        super.animation(time);
        this.cameraLerpFactor += .007;

        this.camera!.position.z = lerp(.9, 1.02, Math.sin(this.cameraLerpFactor) * .25);
        this.camera!.position.y = lerp(.15, .25, Math.sin(this.cameraLerpFactor) * .5);


        if (!this.flag || !this.flag!.geometry)
            return;
        
        
        const positionAttribute = this.flag.geometry.attributes.position
        const positions = positionAttribute.array

        for (let i = 0; i < positions.length; i += 3) {
            const x = positions[i]
            const multipler = ((x + 2.5) / 5)

            const wave1 = 0.5 * Math.sin(0.5 * x + time * 2)
            const wave2 = 0.2 * Math.sin(2 * x + time * 3)

            positions[i + 2] = (wave1 + wave2) * multipler * .01
        }

        positionAttribute.needsUpdate = true

        // requestAnimationFrame(animate)
        // renderer.render(scene, camera)
    }

    private applyPlayerSettings(): void {
        const fbx = assetsList['Shelf'];
        const girl = fbx.scene;
        girl.position.y = -.3;
        girl.rotation.y = Math.PI * 0 / 180;
        this.camera!.position.z = 1;
        this.camera!.position.y = 0.2;
        this.camera!.position.x = 0.3;
        this.camera!.rotation.y = Math.PI / 180 * -3;
        this.camera!.rotation.x = Math.PI / 180 * -4;
        this.camera!.rotation.z = Math.PI / 180 * -1;
        this.scene?.add(girl);

        for (const mesh of fbx.scene.children) {
            if (mesh.name === 'Floor' || mesh.name.indexOf('Plane') >= 0) {
                mesh.receiveShadow = true;

            }
            if (mesh.name.indexOf('Cube') >= 0 || mesh.name === 'Dress') {
                mesh.castShadow = true;

                if(mesh.name !== 'Dress')
                mesh.receiveShadow = true;
            }
        }


        fbx.scene.traverse(skinnedMesh => {
            if ((skinnedMesh as any).material) {
                const material = (skinnedMesh as any).material as MeshStandardMaterial;
                if (material.name.toUpperCase() === 'DRESS') {
                    const mat = new MeshBasicMaterial();
                    mat.map = material.map;
                    // mat.name = DRESS_SIDES.FRONT;// material.name;
                    mat.color = new Color(1,1,1);
                    mat.dithering = false;
                    mat.fog = false;
                    (skinnedMesh as any).material = mat;
                    // setDressMaterial((skinnedMesh as any).material);

                }
            }
        })

        this.addSpotLight();

    }
}