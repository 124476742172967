import { TextField } from "@mui/material";
import { MouseEventHandler, useEffect } from "react";
import { CanvasComponent } from "./canvasComponents/base/CanvasComponent";
import { MaterialButton } from "./MaterialButton";
import { CheckoutThree } from "./three/checkout/checkoutThree";
import React, { useState, ChangeEvent } from "react"
import { Button, ButtonProps } from '@mui/material';
import axios from 'axios';
import ReactGA from "react-ga4";

export function LandingPage() {
    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        ReactGA.initialize("G-0H6C4EJTQH");
    }, []);

    const validateEmail = (email: string): boolean => {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    };
    
    const onEmailChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEmail(event.target.value);
        setIsValidEmail(validateEmail(event.target.value));
    }
    
    const onSubmit = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post('http://13.60.44.200/api/email', { email });

            ReactGA.event({
                category: "Email",
                action: "EmailAdded",
                label: email,
            });
        } catch (e: any) {
            ReactGA.event({
                category: "EmailError",
                action: "EmailRepeat",
                label: email,
            });

            setEmail('');
            setIsValidEmail(false);
            setIsSubmitted(true);
            setIsLoading(false);
        }

        setEmail('');
        setIsValidEmail(false);
        setIsSubmitted(true);
        setIsLoading(false);
        
    }

    const onBackButtonPressed = () => {
        setIsSubmitted(false);
    }

    return (
        <div style={{ width: '100vw', height: '100vh', position:'relative', backgroundColor:'#000' }}>
            <CanvasComponent CanvasClass={CheckoutThree} />
            <div style={{ position: 'absolute', width: '100%', height: '80%', left: '0', top: '0', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
               
                {isSubmitted ? (
                    
                    <div style={{
                        padding: '50px',paddingBottom:'20px', backgroundColor: 'rgba(255,255,255,.9)', borderRadius: '5px', border: '#ccc solid 2px', display: ' flex', alignItems: 'center', justifyContent: 'center', flexDirection:'column', gap:'20px' }}>
                        <p id="title" style={{ fontSize: '24px', fontWeight: '700', margin: 0 }}>
                            Thank You for Signing Up!
                        </p>
                        <MaterialBlackButton text="Back" onClick={onBackButtonPressed} />
                        </div>

                ) : (
                        <div style={{ padding: '50px', paddingBottom:'50px', backgroundColor: 'rgba(255,255,255,.94)', borderRadius: '5px', border: '#ccc solid 2px' }}>
                            <p id="title" style={{ fontSize: '24px', fontWeight: '700', margin: 0 }}>
                                Stay Updated on theDressApp.com!
                            </p>
                            <p>
                                Enter your email to receive updates and be notified when the app launches.
                            </p>
                            <div style={{ width: '100%', display: 'flex', gap: '10px' }}>
                                <TextField id="emailAddressInput" fullWidth label="Email" variant="filled" disabled={isLoading} onChange={onEmailChange} autoComplete='off' value={email} />

                                {(isValidEmail && !isLoading) ? (<MaterialBlackButton text="Submit" onClick={onSubmit} />) : (<MaterialGreyButton text="Submit" disabled={true} />)}
                            </div>
                        </div> 
                )}
                
            </div>
        </div>
    )
}


interface CustomButtonProps extends Omit<ButtonProps, 'onClick'> {
    text: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export function MaterialBlackButton({ text, onClick, ...props }: CustomButtonProps): any {
    return (<MaterialButton {...props} onClick={onClick} sx={{
        backgroundColor: 'black', borderRadius: '1', borderColor: 'black', '&:hover': {
            backgroundColor: 'rgba(90,90,90,1)'
        }, boxShadow: '0 2px 1px rgba(45, 45, 60, 0.2), inset 0 1.5px 1px #525252, inset 0 -2px 1px #606366'
    }}>{text}</MaterialButton>)
}

export function MaterialGreyButton({ text, onClick }: CustomButtonProps) {
    return (<MaterialButton onClick={onClick} disabled={true} sx={{
        backgroundColor: 'rgb(147 147 147)', borderRadius: '1', borderColor: '#a7a7a7', '&:hover': {
            backgroundColor: 'rgba(147,147,147,1)'
        }, boxShadow: '0 2px 1px rgba(45, 45, 60, 0.2), inset 0 1.5px 1px #a7a7a7, inset 0 -2px 1px #797979'
    }}>{text}</MaterialButton>)
}