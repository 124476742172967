import { AppDispatch, RootState } from "../../../redux/Store";

export class BaseCanvas {
    protected dispatch: AppDispatch;
    protected currentState: RootState | null = null;
    protected parentDiv: HTMLDivElement | HTMLCanvasElement;
    protected additionalData: any;

    constructor(div: HTMLDivElement | HTMLCanvasElement, dispatch: AppDispatch, additionalData?: any) {
        this.dispatch = dispatch;
        this.parentDiv = div;
        this.additionalData = additionalData;
        this.currentState = null;
    }

    protected AttachToDiv(div: HTMLDivElement | HTMLCanvasElement, view: HTMLCanvasElement): void {
        while (div.firstChild)
            div?.removeChild(div.firstChild);

        div?.appendChild(view);
    }

    public Resize(): void {

    }

    public Stop(): void {

    }

    public StateUpdate(state: RootState) {
        this.StateChanged(state, this.currentState!);
        this.currentState = state;
    }

    protected StateChanged(state: RootState, oldState?: RootState) {

    }

}