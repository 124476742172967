import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"

type AssetsList = {
    [key: string]: GLTF
}

export type Asset = {
    key: string,
    url: string
}

export enum PlayerAnimationAction {
    RUN_ANIMATION = 'RUN_ANIMATION',
    IDLE_ANIMATION = 'IDLE_ANIMATION'
}
export type AnimationActionArray = { [key: string]: THREE.AnimationAction };

export const assetsList: AssetsList = {};

export function loadAsset(asset: Asset, completeCallback?: (key: string) => void, progressCallback?: (progress: ProgressEvent<EventTarget>) => void): void {
    if (assetsList[asset.key]) {
        completeCallback?.(asset.key);
        return;
    }

    (new GLTFLoader()).loadAsync(asset.url, (progress: any) => {
        progressCallback?.(progress);
    }).then((fbx: any) => {
        assetsList[asset.key] = fbx;
        completeCallback?.(asset.key);
    });
}

export function loadAssetsFromList(assets: Asset[], progressCallback: (percent: number) => void) {
    let loadCount = 0;
    return new Promise((resolve, reject) => {
        for (const asset of assets) {
            loadAsset(asset, () => {
                loadCount++;
                progressCallback(loadCount / assets.length);
                if (loadCount >= assets.length) {
                    resolve(true);
                }
            })
        }
    });
}

export function getRelativeWidth(targetWidth: number, width: number): number {
    return targetWidth * width;
}

export function getRelativeHeight(targetHeight: number, height: number): number {
    return targetHeight * height;
}