import React, { useMemo, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/Hook'
import { AppDispatch } from '../../../redux/Store';
import { onResizeFunctions } from '../../../App';
import { BaseCanvas } from './BaseCanvas';



function PersistentCanvas(divRef: HTMLDivElement | HTMLCanvasElement | undefined, props: Props) {
    const dispatch: AppDispatch = useAppDispatch();
    const baseCanvas = useRef<BaseCanvas>();
    useAppSelector(state => {
        baseCanvas.current?.StateUpdate(state)
    })
    React.useEffect(() => {
        const div: HTMLDivElement | HTMLCanvasElement | undefined = divRef;

        if (!div)
            return;
        baseCanvas.current = new props.CanvasClass(div, dispatch, props.additionalData);

        const canvasResize = () => baseCanvas.current?.Resize();
        // window.addEventListener('resize', canvasResize)

        onResizeFunctions.push({ ref: baseCanvas, function: () => baseCanvas.current?.Resize() })
        // const observer = new ResizeObserver(canvasResize);
        // observer.observe(div);

        return function cleanup() {
            onResizeFunctions.splice(onResizeFunctions.indexOf(onResizeFunctions.filter(obj => obj.ref === baseCanvas)[0]), 1);
            // observer.disconnect();
            // window.removeEventListener('resize', canvasResize)
            baseCanvas.current?.Stop();
        };
    }, [divRef])
}
interface Props {
    CanvasClass: new (div: HTMLDivElement | HTMLCanvasElement, dispatch: AppDispatch, additionalData?: any) => BaseCanvas;
    additionalData?: any
}
export const CanvasComponent = (props: Props) => {
    const [canvasRef, setCanvasRef] = React.useState<HTMLDivElement | HTMLCanvasElement>();
    PersistentCanvas(canvasRef, props);

    return (
        <div style={{ width: '100%', height: '100%', backgroundColor: '#000' }}>
            <div ref={(div) => { if (div) setCanvasRef(div) }}
                style={{ width: '100%', height: '100%' }} />
        </div>
    )
}