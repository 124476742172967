import { createSlice } from "@reduxjs/toolkit";

const initialState: { editMode: boolean } = {
    editMode: false
}
const AppSlice = createSlice({
    initialState,
    name: 'AppSlice',
    reducers: {
        setEditMode: (state, action: { payload: { value: boolean } }) => {
            state.editMode = action.payload.value;
        },
        toggleEditMode: (state) => {
            state.editMode = !state.editMode;
        }
    }
})

export const { setEditMode, toggleEditMode } = AppSlice.actions;
export default AppSlice.reducer;